var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "card-title-actions"
  }, [_c('v-spacer'), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$previous();
      }
    }
  }, [_vm._v("Cancel")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save);
      }
    }
  }, [_vm._v(" Save ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, 'previous');
      }
    }
  }, [_vm._v(" Save & close ")])], 1), _c('v-card-text', {
    staticClass: "pt-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.question.setSlug(_vm.question.slug || _vm.question.name);
      }
    },
    model: {
      value: _vm.question.name,
      callback: function callback($$v) {
        _vm.$set(_vm.question, "name", $$v);
      },
      expression: "question.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "slug",
      "label": "Slug",
      "required": "",
      "disabled": _vm.$route.params.id && !_vm.dev
    },
    on: {
      "change": function change($event) {
        return _vm.question.setSlug(_vm.question.slug);
      }
    },
    model: {
      value: _vm.question.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.question, "slug", $$v);
      },
      expression: "question.slug"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "item-text": "name",
      "item-value": "_id",
      "items": _vm.accounts,
      "name": "accountId",
      "label": "Profile",
      "required": ""
    },
    model: {
      value: _vm.question.accountId,
      callback: function callback($$v) {
        _vm.$set(_vm.question, "accountId", $$v);
      },
      expression: "question.accountId"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "number",
      "name": "sort",
      "label": "Sort",
      "required": ""
    },
    model: {
      value: _vm.question.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.question, "sort", $$v);
      },
      expression: "question.sort"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "checkbox",
      "label": "Is Enabled??"
    },
    model: {
      value: _vm.question.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.question, "isActive", $$v);
      },
      expression: "question.isActive"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "checkbox",
      "label": "Is Verified?"
    },
    model: {
      value: _vm.question.isVerified,
      callback: function callback($$v) {
        _vm.$set(_vm.question, "isVerified", $$v);
      },
      expression: "question.isVerified"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "checkbox",
      "label": "Is default?"
    },
    model: {
      value: _vm.question.default,
      callback: function callback($$v) {
        _vm.$set(_vm.question, "default", $$v);
      },
      expression: "question.default"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }