<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, 'previous')">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-8">
        <v-row>
          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="question.name"
              type="text"
              name="name"
              label="Name"
              required
              @change="question.setSlug(question.slug || question.name)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="question.slug"
              type="text"
              name="slug"
              label="Slug"
              required
              :disabled="$route.params.id && !dev"
              @change="question.setSlug(question.slug)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="question.accountId"
              type="autocomplete"
              item-text="name"
              item-value="_id"
              :items="accounts"
              name="accountId"
              label="Profile"
              required
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="question.sort" type="number" name="sort" label="Sort" required />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="question.isActive" type="checkbox" label="Is Enabled??" />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="question.isVerified" type="checkbox" label="Is Verified?" />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="question.default" type="checkbox" label="Is default?" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { Question } from '@tutti/models';
import { QuestionService, AccountService } from '@tutti/services';

export default {
  name: 'QuestionEdit',

  data() {
    return {
      tab: 0,
      loading: false,
      created: false,
      question: new Question(),
      accounts: [],
    };
  },

  async created() {
    this.tab = this.$route?.params?.tab || 0;

    await this.getQuestion();
    this.getAccounts();
    this.created = true;
  },

  methods: {
    async getAccounts() {
      const response = await AccountService.getAll({ filter: { userType: 'host' } });

      if (response) {
        this.accounts = response.data.data;
      }
    },

    async getQuestion() {
      if (this.$route.params.id) {
        const response = await QuestionService.getById(this.$route.params.id);

        if (response) {
          this.question = new Question(response.data);
          this.$setTitle(this.question.name);
        }
      }
    },

    async save(action) {
      this.loading = true;
      const response = await QuestionService.save(this.question.get());
      if (response) {
        if (action === 'next') {
          this.question = new Question();
          this.$reset();
          this.$setTitle('');
        } else if (action === 'previous') {
          this.$previous();
        } else if (response.data._id) {
          this.$replace(this.$toEdit('question', response.data._id));
        }
      }
      this.loading = false;
    },
  },
};
</script>
